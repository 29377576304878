import React, {useEffect, useState} from 'react';
import styles from './multi-select.module.scss';
import {Error} from "../Error";

type Props = {
    options: Option[],
    onChange: (value: string) => void
    error?: any,
    value: string,
}

type Option = {
    name: string,
    title: string,
}

export const MultiSelect: React.FunctionComponent<Props> = (props: Props) => {
    const [options] = useState<Option[]>(props.options);
    const [activeOptionName, setActiveOptionName] = useState<string>(props.value);

    useEffect(() => {
        setActiveOptionName(props.value);
    }, [props.value]);

    const handleChange = (option: Option) => {
        setActiveOptionName(option.name);
        props.onChange(option.name);
    }

    let optionElements: any = [];
    options.forEach((option: Option, index: number) => {
        const className = [styles.element];

        if (option.name === activeOptionName) {
            className.push(styles.active);
        }

        optionElements.push((
            <div key={index} className={className.join(' ')} onClick={() => handleChange(option)}>
                {option.title}
            </div>
        ));
    });

    return (
        <div className={styles.wrapper}>
            {optionElements}

            <Error error={props.error} />
        </div>
    );
};
