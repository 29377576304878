import React, {useCallback} from 'react';
import Webcam from "react-webcam";
import styles from "./camera.module.scss";
import {Close} from "../../Icons/Close";

type Props = {
    imageCallback: (base64Image: string) => void
    closeCallback: () => void
};

export const Camera: React.FunctionComponent<Props> = (props: Props) => {
    const webcamRef = React.useRef<Webcam>(null);

    const capture = useCallback(
        () => {
            if (webcamRef.current) {
                props.imageCallback(webcamRef.current!.getScreenshot()!);
            }
        },
        [props, webcamRef]
    );

    return (
        <div className={styles.backdrop}>
            <div className={styles.wrapper}>
                <Close className={styles.close} color="#fff" onClick={props.closeCallback} />
                <Webcam
                    audio={false}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    className={styles.video}
                    videoConstraints={{
                        width: 1920,
                        height: 1080,
                        facingMode: "user"
                    }}
                />
                <button type="button" className={styles.button} onClick={capture} />
            </div>
        </div>
    );
};
