import React from 'react';
import styles from './headline.module.scss';

type Props = {
    children: string,
    className?: string,
    color?: 'black' | 'red',
    type?: 'h1' | 'h2' | 'h3',
};

export const Headline: React.FunctionComponent<Props> = (props: Props) => {
    const className = [styles.headline];
    const color = props.color ?? 'black';
    const type = props.type ?? 'h1';

    if (props.className) {
        className.push(props.className);
    }

    if (color === 'red') {
        className.push(styles.red);
    }

    className.push(styles[type]);

    return (
        <div className={className.join(' ')}>
            {props.children}
        </div>
    );
};
