import React from 'react';
import styles from './checkbox.module.scss';
import {FieldError} from "react-hook-form";
import {Error} from "../Error";

type Props = {
    label: any,
    name: string,
    register?: any,
    onChange: (name: string, value: boolean) => void,
    error?: FieldError,
    className?: string,
}

export const Checkbox: React.FunctionComponent<Props> = (props: Props) => {
    return (
        <div className={styles.wrapper + ' ' + props.className}>
            <input id={props.name} className={styles.checkbox} type="checkbox" ref={props.register} name={props.name} onChange={(event) => props.onChange(props.name, event.target.checked)} />
            <label htmlFor={props.name} className={styles.label}>{props.label}</label>
            <Error error={props.error} />
        </div>
    );
};
