import React, {useEffect} from 'react';
import {StepSection} from "../../../modules/StepSection";
import {Input} from "../../../components/Form/Input";
import {Button} from "../../../components/Button";
import {ButtonWrapper} from "../../../components/ButtonWrapper";
import * as yup from "yup";
import {yupResolver} from '@hookform/resolvers/yup';
import {TypeOf} from "yup";
import {useForm} from "react-hook-form";
import {Checkbox} from "../../../components/Form/Checkbox";
import {Grid} from "../../../components/Form/Gird";
import {useParams} from "react-router-dom";
import axios from "axios";
import {RouteParams} from "../index";

const schema = yup.object().shape({
    adultsSourceHousehold: yup.number().required(),
    netSalaryMonthly: yup.number().required(),
    netIncomeRentLeaseMonthly: yup.number().required(),
    alimonyPaymentMonthly: yup.number().required(),
    // childBenefitMonthly: yup.number().required(),
    // privateHealthInsurance: yup.boolean().required(),
    rentalChargeSuspension: yup.boolean().required(),
    costOfLivingBaseline: yup.number().required(),
});

interface FormData extends TypeOf<typeof schema> {}

type Props = {
    stepCompleted: (turbodarlehenId: string) => void,
}

export const Haushaltsrechnung: React.FunctionComponent<Props> = (props: Props) => {
    const params = useParams<RouteParams>();

    const {register, handleSubmit, errors, clearErrors, reset} = useForm<FormData>({
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/turbodarlehen/${params.antragsId}/haushalt`).then((response) => {
            // @ts-ignore
            reset(response.data);
        });
    }, [params, reset]);

    const onSubmit = (data: FormData) => {
        // data.privateHealthInsurance = false;
        axios.post(`${process.env.REACT_APP_API_URL}/turbodarlehen/${params.antragsId}/haushalt`, data).then((response) => {
            props.stepCompleted(params.antragsId!);
        });
    };

    const onChange = (name: string) => {
        clearErrors(name);
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div>
                <StepSection headline="Für die Erstellung der Haushaltsrechnung">
                    <Grid>
                        <Input onChange={onChange} error={errors?.adultsSourceHousehold} name="adultsSourceHousehold" register={register} placeholder="Personen im Haushalt" />
                        <Input onChange={onChange} error={errors?.netSalaryMonthly} name="netSalaryMonthly" register={register} placeholder="Einkommen (Netto)" />
                        <Input onChange={onChange} error={errors?.netIncomeRentLeaseMonthly} name="netIncomeRentLeaseMonthly" register={register} placeholder="Einkünfte auf Vermietung und Verpackung (Netto)" />
                        <Input onChange={onChange} error={errors?.alimonyPaymentMonthly} name="alimonyPaymentMonthly" register={register} placeholder="Unterhaltszahlungen" />
                        {/*<Input onChange={onChange} error={errors?.childBenefitMonthly} name="childBenefitMonthly" register={register} placeholder="Monatliches Kindergeld" />*/}
                        <Input onChange={onChange} error={errors?.costOfLivingBaseline} name="costOfLivingBaseline" register={register} placeholder="Lebenshaltungskosten" />

                        {/*<Checkbox onChange={onChange} error={errors?.privateHealthInsurance} register={register} name="privateHealthInsurance" label="Private Krankenversicherung" />*/}
                        <Checkbox onChange={onChange} error={errors?.rentalChargeSuspension} register={register} name="rentalChargeSuspension" label="Ausgaben für Miete" />
                    </Grid>
                </StepSection>
            </div>

            <ButtonWrapper>
                <Button buttonStyle="secondary" to={`/turbodarlehen/antrag/${params.antragsId}/schritt/2`}>Zurück</Button>
                <Button type="submit">Weiter</Button>
            </ButtonWrapper>
        </form>
    );
};
