import React from 'react';
import { Link } from 'react-router-dom';
import styles from './button.module.scss';

type Props = {
    onClick?: () => void,
    children: string,
    buttonStyle?: 'primary' | 'secondary',
    type?: 'button' | 'submit',
    to?: string,
}

export const Button: React.FunctionComponent<Props> = (props: Props) =>     {
    const style = props.buttonStyle ?? 'primary';
    const type = props.type ?? 'button';

    const className = [styles.button];

    if (style === 'secondary') {
        className.push(styles.secondary)
    }

    if (props.to) {
        return (
            <Link className={className.join(' ')} to={props.to}>{props.children}</Link>
        );
    }

    return (
        <button className={className.join(' ')} type={type} onClick={props.onClick}>{props.children}</button>
    );
};
