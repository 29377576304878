import React, {useState} from 'react';
import {Button} from "../../../components/Button";
import {ButtonWrapper} from "../../../components/ButtonWrapper";
import {Link, useParams} from "react-router-dom";
import {RouteParams} from "../index";
import styles from "./legitimation.module.scss";
import {Checkbox} from "../../../components/Form/Checkbox";

export const LegitimationAuswaehlen: React.FunctionComponent = () => {
    const params = useParams<RouteParams>();

    const [useMock, setUseMock] = useState<boolean>();

    let nectTarget = `/turbodarlehen/antrag/${params.antragsId}/nect`;
    if (useMock) {
        nectTarget = `/turbodarlehen/antrag/${params.antragsId}/nect/mock`;
    }

    console.log(nectTarget);

    return (
        <>
            <div className={styles.wrapper}>
                <Link className={styles.box} to={`/turbodarlehen/antrag/${params.antragsId}/schritt/6`}>Legitimation selber durchführen</Link>
                <div>
                    <Link className={styles.box} to={nectTarget}>Nect Legitimation</Link>
                    <Checkbox label="Nect-Mock" name="nect" onChange={(name, value) => setUseMock(value)} className={styles.checkbox}/>
                </div>
            </div>

            <ButtonWrapper>
                <Button to={`/turbodarlehen/antrag/${params.antragsId}/schritt/4`} buttonStyle="secondary">Zurück</Button>
            </ButtonWrapper>
        </>
    );
};
