import React from 'react';
import NumberFormat, {NumberFormatValues} from "react-number-format";
import styles from "./numberSelect.module.scss";
import {Error} from "../Error";

type Props = {
    onChange: (value: number) => void,
    value: number,
    step: number,
    suffix: string,
    error: any,
}

export const NumberSelect: React.FunctionComponent<Props> = (props: Props) => {
    return (
        <>
            <div className={styles.wrapper}>
                <button className={styles.button} type="button" onClick={() => {props.onChange(props.value - props.step)}}>-</button>
                <NumberFormat className={styles.input} value={props.value} onValueChange={(values: NumberFormatValues) => props.onChange(values.floatValue!)} suffix={' ' + props.suffix} thousandSeparator="." decimalSeparator="," />
                <button className={styles.button} type="button" onClick={() => {props.onChange(props.value + props.step)}}>+</button>
            </div>

            <Error className={styles.error} error={props.error} />
        </>
    );
};
