import React, {useRef, useState} from 'react';
import {StepSection} from "../../../modules/StepSection";
import ReactSignatureCanvas from "react-signature-canvas";
import styles from './signature.module.scss';
import {ButtonWrapper} from "../../../components/ButtonWrapper";
import {Button} from "../../../components/Button";
import {useParams} from "react-router-dom";
import {RouteParams} from "../index";

type Props = {
    stepCompleted: (turbodarlehenId: string) => void,
}

export const SignDocument: React.FunctionComponent<Props> = (props: Props) => {
    const params = useParams<RouteParams>();
    const [state, setState] = useState<'preview' | 'sign'>('preview');

    const sigCanvas = useRef<ReactSignatureCanvas | undefined>();

    const saveSign = () => {
        if (sigCanvas.current) {
            console.log(sigCanvas.current.toDataURL());
            props.stepCompleted(params.antragsId!);
        }
    }

    let content;
    if (state === 'preview') {
        content = (
            <>
                <div>
                    <StepSection headline="Bitte lassen Sie Ihren Kunden die Darlehensunterlagen unterschreiben.">
                        <img className={styles.preview} src="/images/dahrlehensvertag.jpg" alt=""/>
                    </StepSection>
                </div>

                <ButtonWrapper>
                    <Button buttonStyle="secondary" to={`/turbodarlehen/antrag/${params.antragsId}/schritt/9`}>Zurück</Button>
                    <Button onClick={() => setState('sign')}>Signieren</Button>
                </ButtonWrapper>
            </>
        );
    } else {
        content = (
            <>
                <div>
                    <StepSection headline="Bitte lassen Sie Ihren Kunden die Darlehensunterlagen unterschreiben.">
                        {/*@ts-ignore*/}
                        <ReactSignatureCanvas canvasProps={{className: styles.sigBox}} ref={sigCanvas} />
                    </StepSection>
                </div>

                <ButtonWrapper>
                    <Button buttonStyle="secondary" onClick={() => setState('preview')}>Zurück</Button>
                    <Button onClick={saveSign}>Unterschrift akzeptieren & fortfahren</Button>
                </ButtonWrapper>
            </>
        );
    }

    return (
        <>
            {content}
        </>
    );
};
