import React, {useEffect, useState} from 'react';
import axios from "axios";
import {Button} from "../../../components/Button";
import {ButtonWrapper} from "../../../components/ButtonWrapper";
import styles from "./antragsuebersicht.module.scss";

type Element = {
    id: number
    finanzierungssumme: number,
    vorname: string,
    nachname: string,
    status: string
};

export const AntragsUebersicht: React.FunctionComponent = () => {
    const [data, setData] = useState<Element[]>([]);

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL + '/turbodarlehen').then((response) => {
            const elements = response.data.map((element: any) => {
                return {
                    id: element.id,
                    finanzierungssumme: element.finanzierungssumme,
                    vorname: element.personen[0].vorname,
                    nachname: element.personen[0].nachname,
                    status: element.status,
                };
            });

            setData(elements);
        });
    }, []);

    const elemente = data.map((element: Element) => {
        return (
            <tr>
                <td>{element.finanzierungssumme.toLocaleString('de')} €</td>
                <td>{element.vorname}</td>
                <td>{element.nachname}</td>
                <td>{element.status}</td>
                <td><Button to={`/turbodarlehen/antrag/${element.id}/schritt/1`}>bearbeiten</Button></td>
            </tr>
        );
    });

    return (
        <>
            <table className={styles.table}>
                <tr>
                    <th>Finanzierugssumme</th>
                    <th>Vorname</th>
                    <th>Nachname</th>
                    <th>Status</th>
                    <th />
                </tr>

                {elemente}
            </table>

            <ButtonWrapper reverse={true}>
                <Button to="/turbodarlehen/neu">neuer Antrag</Button>
            </ButtonWrapper>
        </>
    );
};
