import React, {useState} from 'react';
import styles from './input.module.scss';
import {FieldError} from "react-hook-form";
import {Error} from "../Error";
import {Camera} from "../../Camera";

type Props = {
    label: string,
    name: string,
    error?: FieldError,
    onChange: (base64Image: string) => void
}

export const PhotoCapture: React.FunctionComponent<Props> = (props: Props) => {
    const [cameraActive, setCameraActive] = useState<boolean>(false);
    const [base64Image, setBase64Image] = useState<string>();

    let camera;
    if (cameraActive) {
        camera = (
            <Camera closeCallback={() => setCameraActive(false)} imageCallback={(imageBase64: string) => {
                setBase64Image(imageBase64);
                props.onChange(imageBase64);
                setCameraActive(false);
            }} />
        );
    }

    let image;
    if (base64Image) {
        image = (
            <img className={styles.preview} src={base64Image} alt="preview"/>
        );
    }

    return (
        <>
            {camera}
            <div className={styles.wrapper}>
                {image}
                <div onClick={() => setCameraActive(true)} className={styles.label} data-title={props.label} />
                <Error error={props.error} />
            </div>
        </>
    );
};
