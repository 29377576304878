import React from 'react';
import styles from './grid.module.scss';

type Props = {
    children: any,
}

export const Grid: React.FunctionComponent<Props> = (props: Props) => {
    return (
        <div className={styles.grid}>
            {props.children}
        </div>
    );
};
