import React from 'react';
import './App.scss';
import {Header} from "./modules/Header";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from "react-router-dom";
import {Turbodarlehen} from "./views/Turbodarlehen";

function App() {
    return (
        <div className="App">
            <Router>
                <Header />
                <Switch>
                    <Route path="/turbodarlehen">
                        <Turbodarlehen />
                    </Route>

                    <Route exact path="/">
                        <Redirect to="/turbodarlehen" />
                    </Route>
                </Switch>
            </Router>
        </div>
    );
}

export default App;
