import React from 'react';

type Props = {
    color: string
    onClick: () => void
    className: string
};

export const Close: React.FunctionComponent<Props> = (props: Props) => {
    return (
        <svg className={props.className} onClick={props.onClick} xmlns="http://www.w3.org/2000/svg" width="92.213" height="92.213" viewBox="0 0 92.213 92.213">
            <g id="Gruppe_1" data-name="Gruppe 1" transform="translate(-3.893 -3.893)">
                <line id="Linie_1" data-name="Linie 1" x1="71" y2="71" transform="translate(14.5 14.5)" fill="none" stroke={props.color} strokeLinecap="round" strokeWidth="15"/>
                <line id="Linie_2" data-name="Linie 2" x1="71" y2="71" transform="translate(85.5 14.5) rotate(90)" fill="none" stroke={props.color} strokeLinecap="round" strokeWidth="15"/>
            </g>
        </svg>
    );
};
