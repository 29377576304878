import React, {useEffect, useState} from 'react';
import {StepSection} from "../../../modules/StepSection";
import {Results} from "../../../modules/Results";
import {Button} from "../../../components/Button";
import {ButtonWrapper} from "../../../components/ButtonWrapper";
import {useParams} from "react-router-dom";
import {RouteParams} from "../index";
import axios from "axios";

type Props = {
    stepCompleted: (turbodarlehenId: string) => void,
}

interface Person {
    vorname: string,
    nachname: string,
    geburtsdatum: Date,
    geburtsort?: string,
    identifikationsnachweis: {
        type: string,
        nectVorgang?: {
            behoerde: string,
            idExpire: Date,
        }
    },
    adresse: {
        straße: string,
        hausnummer: string,
        postleitzahl: string,
        ort: string,
    }
}

export const KundendatenZusammenfassung: React.FunctionComponent<Props> = (props: Props) => {
    const params = useParams<RouteParams>();

    const [data, setData] = useState<Person>();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/turbodarlehen/${params.antragsId}/person`).then((response) => {
            if (response.data.identifikationsnachweis.type === 'nect') {
                setData({
                    vorname: response.data.identifikationsnachweis.nectVorgang.vorname,
                    nachname: response.data.identifikationsnachweis.nectVorgang.nachname,
                    geburtsdatum: new Date(response.data.identifikationsnachweis.nectVorgang.geburtsdatum),
                    geburtsort: response.data.identifikationsnachweis.nectVorgang.geburtsort,
                    adresse: {
                        straße: response.data.identifikationsnachweis.nectVorgang.adresse.straße,
                        hausnummer: '',
                        ort: response.data.identifikationsnachweis.nectVorgang.adresse.ort,
                        postleitzahl: response.data.identifikationsnachweis.nectVorgang.adresse.postleitzahl,
                    },
                    identifikationsnachweis: {
                        type: response.data.identifikationsnachweis.type,
                        nectVorgang: {
                            idExpire: new Date(response.data.identifikationsnachweis.nectVorgang.idExpire),
                            behoerde: response.data.identifikationsnachweis.nectVorgang.behoerde,
                        }
                    }
                });
            } else {
                setData({
                    vorname: response.data.vorname,
                    nachname: response.data.nachname,
                    geburtsdatum: new Date(response.data.geburtsdatum),
                    geburtsort: undefined,
                    adresse: {
                        straße: response.data.adresse.straße,
                        hausnummer: response.data.adresse.hausnummer,
                        ort: response.data.adresse.ort,
                        postleitzahl: response.data.adresse.postleitzahl,
                    },
                    identifikationsnachweis: {
                        type: response.data.identifikationsnachweis.type,
                    }
                });
            }
            setLoading(false);
        });
    }, [params.antragsId]);

    if (loading || !data) {
        return (<></>);
    }

    return (
        <>
            <div>
                <StepSection headline="Ihr Kunde wurde erfolgreich legitimiert.">
                    <Results columns={
                        [
                            {
                                results: [
                                    {
                                        label: "Kundennummer",
                                        value: "-",
                                    },
                                    {
                                        label: "Legitimiert am",
                                        value: new Date().toLocaleDateString('de'),
                                    },
                                    {
                                        label: "Typ/ Nr.",
                                        value: data.identifikationsnachweis.type === 'personalausweis' ? 'Personalausweis' : 'Nect',
                                    }
                                ]
                            },
                            {
                                results: [
                                    {
                                        label: "Name",
                                        value: data.nachname,
                                    },
                                    {
                                        label: "Straße / Nr.",
                                        value: `${data.adresse.straße}, ${data.adresse.hausnummer}`
                                    },
                                    {
                                        label: "Geburtsdatum",
                                        value: data.geburtsdatum.toLocaleDateString('de'),
                                    },
                                    {
                                        label: "Geburtsort",
                                        value: data.geburtsort ?? '-',
                                    }
                                ]
                            },
                            {
                                results: [
                                    {
                                        label: "Vorname",
                                        value: data.vorname,
                                    },
                                    {
                                        label: "PLZ / Ort",
                                        value: `${data.adresse.postleitzahl}, ${data.adresse.ort}`
                                    },
                                    {
                                        label: "Nationalität",
                                        value: "-",
                                    },
                                    {
                                        label: "Gültig bis",
                                        value: data.identifikationsnachweis!.nectVorgang?.idExpire.toLocaleDateString('de') ?? '-',
                                    }
                                ]
                            }
                        ]
                    } />
                </StepSection>
            </div>

            <ButtonWrapper reverse={true}>
                <Button onClick={() => props.stepCompleted(params.antragsId!)}>Akzeptieren & fortfahren</Button>
            </ButtonWrapper>
        </>
    );
};
