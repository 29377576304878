import React from 'react';
import {Route, Switch, useHistory, useRouteMatch} from "react-router-dom";
import {HeadlineBlock} from "../../modules/HeadlineBlock";
import {Container} from "../../layout/Container";
import {Box} from "../../components/Box";
import {Finanzierungsvorhaben} from "./Finanzierungsvorhaben";
import {Konditionen} from "./Konditionen";
import {Haushaltsrechnung} from "./Haushaltsrechnung";
import {HaushaltsrechnungAuswaehlen} from "./HaushaltsrechnungAuswaehlen";
import {Personalausweis} from "./Personalausweis";
import {KundendatenZusammenfassung} from "./KundendatenZusammenfassung";
import {AntragsUebersicht} from "./AntragsUebersicht";
import {LegitimationAuswaehlen} from "./LegitimationAuswaehlen";
import {LegitimationAbgeschlossen} from "./LegitimationAbgeschlossen";
import {UnterlagenUnterzeichnenAuswaehlen} from "./UnterlagenUnterzeichnenAuswaehlen";
import {SignDocument} from "./SignDocument";
import {AntragsAbschluss} from "./AntragsAbschluss";
import {NectLegitimation} from "./NectLegitimation";

export interface RouteParams {
    antragsId?: string,
}

export const Turbodarlehen: React.FunctionComponent = () => {
    let {path} = useRouteMatch();
    const history = useHistory();

    return (
        <Switch>
            <Route path={`${path}/antrag/:antragsId/schritt/11`}>
                <HeadlineBlock headline="Ihr Wüstenrot" subheadline="Wohndarlehen Turbo" />
                <Container>
                    <Box>
                        <AntragsAbschluss />
                    </Box>
                </Container>
            </Route>

            <Route path={`${path}/antrag/:antragsId/schritt/10`}>
                <HeadlineBlock headline="Ihr Wüstenrot" subheadline="Wohndarlehen Turbo" />
                <Container>
                    <Box>
                        <SignDocument stepCompleted={(turbodarlehenId: string) => {
                            history.push(`${path}/antrag/${turbodarlehenId}/schritt/11`);
                        }} />
                    </Box>
                </Container>
            </Route>

            <Route path={`${path}/antrag/:antragsId/schritt/9`}>
                <HeadlineBlock headline="Ihr Wüstenrot" subheadline="Wohndarlehen Turbo" />
                <Container>
                    <Box>
                        <UnterlagenUnterzeichnenAuswaehlen />
                    </Box>
                </Container>
            </Route>

            <Route path={`${path}/antrag/:antragsId/schritt/8`}>
                <HeadlineBlock headline="Ihr Wüstenrot" subheadline="Wohndarlehen Turbo" />
                <Container>
                    <Box>
                        <LegitimationAbgeschlossen />
                    </Box>
                </Container>
            </Route>

            <Route path={`${path}/antrag/:antragsId/schritt/7`}>
                <HeadlineBlock headline="Ihr Wüstenrot" subheadline="Wohndarlehen Turbo" />
                <Container>
                    <Box>
                        <KundendatenZusammenfassung stepCompleted={(turbodarlehenId: string) => {
                            history.push(`${path}/antrag/${turbodarlehenId}/schritt/8`);
                        }} />
                    </Box>
                </Container>
            </Route>

            <Route path={`${path}/antrag/:antragsId/schritt/6`}>
                <HeadlineBlock headline="Ihr Wüstenrot" subheadline="Wohndarlehen Turbo" />
                <Container>
                    <Box>
                        <Personalausweis stepCompleted={(turbodarlehenId) => {
                            history.push(`${path}/antrag/${turbodarlehenId}/schritt/7`);
                        }} />
                    </Box>
                </Container>
            </Route>

            <Route path={`${path}/antrag/:antragsId/nect/:mock?`}>
                <HeadlineBlock headline="Ihr Wüstenrot" subheadline="Wohndarlehen Turbo" />
                <Container>
                    <Box>
                        <NectLegitimation stepCompleted={(turbodarlehenId) => {
                            history.push(`${path}/antrag/${turbodarlehenId}/schritt/7`);
                        }} />
                    </Box>
                </Container>
            </Route>

            <Route path={`${path}/antrag/:antragsId/schritt/5`}>
                <HeadlineBlock headline="Ihr Wüstenrot" subheadline="Wohndarlehen Turbo" />
                <Container>
                    <Box>
                        <LegitimationAuswaehlen />
                    </Box>
                </Container>
            </Route>

            <Route path={`${path}/antrag/:antragsId/schritt/4`}>
                <HeadlineBlock headline="Ihr Wüstenrot" subheadline="Wohndarlehen Turbo" />
                <Container>
                    <Box>
                        <Konditionen stepCompleted={(turbodarlehenId) => {
                            history.push(`${path}/antrag/${turbodarlehenId}/schritt/5`);
                        }} />
                    </Box>
                </Container>
            </Route>

            <Route path={`${path}/antrag/:antragsId/schritt/3`}>
                <HeadlineBlock headline="Ihr Wüstenrot" subheadline="Wohndarlehen Turbo" />
                <Container>
                    <Box>
                        <Haushaltsrechnung stepCompleted={(turbodarlehenId) => {
                            history.push(`${path}/antrag/${turbodarlehenId}/schritt/4`);
                        }} />
                    </Box>
                </Container>
            </Route>

            <Route path={`${path}/antrag/:antragsId/schritt/2`}>
                <HeadlineBlock headline="Ihr Wüstenrot" subheadline="Wohndarlehen Turbo" />
                <Container>
                    <Box>
                        <HaushaltsrechnungAuswaehlen />
                    </Box>
                </Container>
            </Route>

            <Route exact path={`${path}/antrag/:antragsId/schritt/1`}>
                <HeadlineBlock headline="Bitte geben Sie uns einige" subheadline="Informationen zum Finanzierungsvorhaben." />
                <Container>
                    <Box>
                        <Finanzierungsvorhaben stepCompleted={(turbodarlehenId) => {
                            history.push(`${path}/antrag/${turbodarlehenId}/schritt/2`);
                        }} />
                    </Box>
                </Container>
            </Route>

            <Route exact path={`${path}/neu`}>
                <HeadlineBlock headline="Bitte geben Sie uns einige" subheadline="Informationen zum Finanzierungsvorhaben." />
                <Container>
                    <Box>
                        <Finanzierungsvorhaben stepCompleted={(turbodarlehenId) => {
                            history.push(`${path}/antrag/${turbodarlehenId}/schritt/2`);
                        }} />
                    </Box>
                </Container>
            </Route>

            <Route exact path={path}>
                <HeadlineBlock headline="Bitte geben Sie uns einige" subheadline="Informationen zum Finanzierungsvorhaben." />
                <Container>
                    <Box>
                        <AntragsUebersicht />
                    </Box>
                </Container>
            </Route>
        </Switch>
    );
};
