import React from 'react';
import {StepSection} from "../../../modules/StepSection";
import {Input} from "../../../components/Form/Input";
import {Button} from "../../../components/Button";
import {ButtonWrapper} from "../../../components/ButtonWrapper";
import * as yup from "yup";
import {yupResolver} from '@hookform/resolvers/yup';
import {TypeOf} from "yup";
import {Controller, useForm} from "react-hook-form";
import axios from "axios";
import styles from './personalausweis.module.scss';
import {useParams} from "react-router-dom";
import {RouteParams} from "../index";
import {PhotoCapture} from "../../../components/Form/PhotoCapture";

const schema = yup.object().shape({
    vorderseite: yup.string(),
    rueckseite: yup.string(),
    ausweisnummer: yup.string().required(),
    can: yup.string().required(),
    behoerde: yup.string().required(),
    ausstellungsdatum: yup.date().required(),
});

interface FormData extends TypeOf<typeof schema> {}

type Props = {
    stepCompleted: (turbodarlehenId: string) => void,
}

export const Personalausweis: React.FunctionComponent<Props> = (props: Props) => {
    const params = useParams<RouteParams>();

    const {register, handleSubmit, errors, clearErrors, setValue, control} = useForm<FormData>({
        resolver: yupResolver(schema),
    });

    const onSubmit = (data: FormData) => {
        axios.post(`${process.env.REACT_APP_API_URL}/turbodarlehen/${params.antragsId}/identifikationsnachweis`, data).then((response) => {
            props.stepCompleted(params.antragsId!);
        });
    };

    const onChange = (name: string) => {
        clearErrors(name);
    }


    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div>
                <StepSection headline="Personalausweis">
                    <div className={styles.grid}>
                        <div className={[styles.element, styles.fileInputWrapper].join(' ')}>
                            <Controller
                                name="vorderseite"
                                control={control}
                                defaultValue=""
                                render={({ onChange }) => (
                                    <PhotoCapture label="Ausweis Vorderseite" name="vorderseite" onChange={onChange} />
                                )}
                            />
                            <Controller
                                name="rueckseite"
                                control={control}
                                defaultValue=""
                                render={({ onChange }) => (
                                    <PhotoCapture label="Ausweis Rückseite" name="rueckseite" onChange={(base64Image: any) => {
                                        axios.post(process.env.REACT_APP_API_URL + '/personalausweis/texterkennung', {
                                            image: base64Image,
                                        }).then((response) => {
                                            setValue('ausweisnummer', response.data.ausweisnummer);
                                        });

                                        onChange(base64Image);
                                    }} />
                                )}
                            />
                        </div>

                        <div className={styles.element}>
                            <div className={styles.formRow}><Input onChange={onChange} error={errors?.ausweisnummer} name="ausweisnummer" register={register} placeholder="Ausweisnummer" /></div>
                            <div className={styles.formRow}><Input onChange={onChange} error={errors?.can} name="can" register={register} placeholder="CAN" /></div>
                            <div className={styles.formRow}><Input onChange={onChange} error={errors?.behoerde} name="behoerde" register={register} placeholder="Behörde" /></div>
                            <div className={styles.formRow}><Input onChange={onChange} error={errors?.ausstellungsdatum} name="ausstellungsdatum" register={register} placeholder="Ausstellungsdatum" /></div>
                        </div>
                    </div>
                </StepSection>
            </div>

            <ButtonWrapper>
                <Button buttonStyle="secondary" to={`/turbodarlehen/antrag/${params.antragsId}/schritt/5`}>Zurück</Button>
                <Button type="submit">Weiter</Button>
            </ButtonWrapper>
        </form>
    );
};
