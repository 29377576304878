import React from 'react';
import styles from './header.module.scss';
import {Container} from "../../layout/Container";
import { Link } from 'react-router-dom';

export const Header = () => {
    return (
        <Container>
            <div className={styles.header}>
                <Link to={"/turbodarlehen"}><img className={styles.logo} src="/images/logo/logo.svg" alt="" /></Link>
            </div>
        </Container>
    );
};
