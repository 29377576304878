import React from 'react';
import styles from './headline.module.scss';
import {Container} from "../../layout/Container";
import {Headline} from "../../components/Headline";

type Props = {
    headline: string,
    subheadline: string,
};

export const HeadlineBlock: React.FunctionComponent<Props> = (props: Props) => {
    return (
        <Container>
            <div className={styles.wrapper}>
                <Headline>{props.headline}</Headline>
                <Headline color="red">{props.subheadline}</Headline>
            </div>
        </Container>
    );
};
