import React from 'react';
import styles from './input.module.scss';
import {FieldError} from "react-hook-form";
import {Error} from "../Error";

type Props = {
    placeholder?: string,
    register: any,
    name: string,
    error?: FieldError,
    type?: 'text' | 'checkbox' | 'file' | 'date',
    onChange: (name: string) => void,
}

export const Input: React.FunctionComponent<Props> = (props: Props) => {
    const type = props.type ?? 'text';

    return (
        <div>
            <input type={type} ref={props.register} name={props.name} className={styles.input} placeholder={props.placeholder} onChange={() => props.onChange(props.name)} />
            <Error error={props.error} />
        </div>
    );
};
