import React from 'react';
import {Link, useParams} from "react-router-dom";
import {RouteParams} from "../index";
import styles from "./legitimation.module.scss";
import {StepSection} from "../../../modules/StepSection";

export const UnterlagenUnterzeichnenAuswaehlen: React.FunctionComponent = () => {
    const params = useParams<RouteParams>();

    return (
        <>
            <div>
                <StepSection headline="Sie können die Darlehensunterlagen Ihrem Kunden nun zur Verfügung stellen und diesen auch direkt digital unterzeichnen lassen">
                    <></>
                </StepSection>
            </div>

            <div className={styles.wrapper}>
                <div className={styles.box} >Darlehnsunterlagen ausdrucken</div>
                <Link className={styles.box} to={`/turbodarlehen/antrag/${params.antragsId}/schritt/10`}>Vertragsunterzeichnung digital</Link>
            </div>
        </>
    );
};
