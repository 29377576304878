import React, {useEffect, useState} from 'react';
import {StepSection} from "../../../modules/StepSection";
import {Results} from "../../../modules/Results";
import {Button} from "../../../components/Button";
import {ButtonWrapper} from "../../../components/ButtonWrapper";
import {Legal} from "../../../components/Legal";
import {useParams} from "react-router-dom";
import {RouteParams} from "../index";
import axios from "axios";

type Props = {
    stepCompleted: (turbodarlehenId: string) => void,
}

interface Kondition {
    durationInMonths: number,
    effectiveInterestRate: number,
    interestRate: number,
    loanAmount: number,
    payDownAmountMonthly: number,
}

export const Konditionen: React.FunctionComponent<Props> = (props: Props) => {
    const params = useParams<RouteParams>();

    const [akzeptiert, setAkzeptiert] = useState<boolean>(false);
    const [data, setData] = useState<Kondition>();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/turbodarlehen/${params.antragsId}`).then((response) => {
            if (response.data.status === 'akzeptiert') {
                setAkzeptiert(true);
            }
        });

        axios.get(`${process.env.REACT_APP_API_URL}/turbodarlehen/${params.antragsId}/kondition`).then((response) => {
            setData({
                durationInMonths: response.data.durationInMonths,
                effectiveInterestRate: response.data.effectiveInterestRate,
                interestRate: response.data.interestRate,
                loanAmount: response.data.loanAmount,
                payDownAmountMonthly: response.data.payDownAmountMonthly,
            });
            setLoading(false);
        });
    }, [params.antragsId]);

    if (loading || !data) {
        return (<></>);
    }

    if (!akzeptiert) {
        return (
            <>
                <StepSection headline="Wir können ihrem Kunden leider keine Konditionen anbieten.">
                    <ButtonWrapper>
                        <Button to={`/turbodarlehen/antrag/${params.antragsId}/schritt/1`}>Antrag überarbeiten</Button>
                    </ButtonWrapper>
                </StepSection>
            </>
        );
    }

    return (
        <>
            <div>
                <StepSection headline="Folgende Konditionen können wir ihrem Kunden anbieten">
                    <Results columns={
                        [
                            {
                                results: [
                                    {
                                        label: "Kreditbetrag",
                                        value: `${data.loanAmount.toLocaleString('de')} €`,
                                    }
                                ]
                            },
                            {
                                results: [
                                    {
                                        label: "Sollzins",
                                        value: `${data.interestRate.toLocaleString('de')} % p.a.`,
                                    },
                                    {
                                        label: "Effektiver Jahreszins",
                                        value: `${data.effectiveInterestRate.toLocaleString('de')} % p.a.`,
                                    }
                                ]
                            },
                            {
                                results: [
                                    {
                                        label: "Laufzeit",
                                        value: `${Math.floor(data.durationInMonths / 12)} Jahre, ${data.durationInMonths - (Math.floor(data.durationInMonths / 12) * 12)} Monate`,
                                    },
                                    {
                                        label: "Monatliche Rate",
                                        value: `${data.payDownAmountMonthly.toLocaleString('de')} €`,
                                    }
                                ]
                            },
                        ]
                    } />
                </StepSection>
            </div>

            <ButtonWrapper>
                <Legal>
                    *Die errechnete Kondition basiert auf dem heute gültigen Sollzinssatz.
                    Der Sollzinssatz kann sich bis zur Vertragserstellung,
                    in Abhänigkeit von Kapitalmarktschwankungen, noch verändern. Gilt nur in
                    Verbindung mit Abschluss eines Bausparvertrags Tarifvariante D/KF.
                </Legal>
                <Button onClick={() => props.stepCompleted(params.antragsId!)}>Mit der Legitimation fortfahren</Button>
            </ButtonWrapper>
        </>
    );
};
