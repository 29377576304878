import React, {useEffect, useState} from 'react';
import {Button} from "../../../components/Button";
import {ButtonWrapper} from "../../../components/ButtonWrapper";
import {useParams} from "react-router-dom";
import {RouteParams} from "../index";
import {StepSection} from "../../../modules/StepSection";
import QRCode from "react-qr-code";
import styles from "./nect.module.scss";
import axios from "axios";
import {Loading} from "../../../components/Loading";

type Props = {
    stepCompleted: (turbodarlehenId: string) => void,
}

interface NectParams extends RouteParams {
    mock: string
}

export const NectLegitimation: React.FunctionComponent<Props> = (props: Props) => {
    const params = useParams<NectParams>();

    const [uuid, setUuid] = useState();

    useEffect(() => {
        axios.post(process.env.REACT_APP_API_URL + `/ident/turbodarlehen/${params.antragsId!}/nect/create`, {
            mock: params.mock === 'mock',
        }).then((response) => {
            setUuid(response.data.uuid);
        });
    }, [params]);

    useEffect(() => {
        let intervalId: any;

        if (uuid) {
            intervalId = setInterval(() => {
                axios.get(process.env.REACT_APP_API_URL + `/ident/nect/status/${uuid}`).then((response) => {
                    if (response.data.nectStatus === 'ready') {
                        clearInterval(intervalId);
                        props.stepCompleted(params.antragsId!);
                    }
                });
            }, 1000);
        }

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        }
    }, [params, uuid, props])

    if (!uuid) {
        return (
            <>
                <div>
                    <StepSection headline="Scannen Sie den Qr-Code um sich über Nect zu legitimieren.">
                        <div className={styles.qrcode}>
                            <Loading />
                        </div>
                    </StepSection>
                </div>

                <ButtonWrapper>
                    <Button to={`/turbodarlehen/antrag/${params.antragsId}/schritt/5`} buttonStyle="secondary">Zurück</Button>
                </ButtonWrapper>
            </>
        );
    }

    return (
        <>
            <div>
                <StepSection headline="Scannen Sie den Qr-Code um sich über Nect zu legitimieren.">
                    <div className={styles.qrcode}>
                        <QRCode value={`https://test.jump.nect.com?case_uuid=${uuid}`} />
                    </div>
                </StepSection>
            </div>

            <ButtonWrapper>
                <Button to={`/turbodarlehen/antrag/${params.antragsId}/schritt/5`} buttonStyle="secondary">Zurück</Button>
            </ButtonWrapper>
        </>
    );
};
