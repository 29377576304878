import React from 'react';
import {Button} from "../../../components/Button";
import {ButtonWrapper} from "../../../components/ButtonWrapper";
import {useParams} from "react-router-dom";
import {RouteParams} from "../index";
import {StepSection} from "../../../modules/StepSection";

export const LegitimationAbgeschlossen: React.FunctionComponent = () => {
    const params = useParams<RouteParams>();

    return (
        <>
            <div>
                <StepSection headline="Die Legitimation war erfolgreich. Sie können nun den Prozess fortsetzen.">
                    <img src="/images/success.png" alt="" style={{
                        width: '200px',
                        margin: '0 auto'
                    }} />
                </StepSection>
            </div>

            <ButtonWrapper reverse={true}>
                <Button to={`/turbodarlehen/antrag/${params.antragsId}/schritt/9`} buttonStyle="primary">Weiter</Button>
            </ButtonWrapper>
        </>
    );
};
