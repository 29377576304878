import React from 'react';
import {StepSection} from "../../../modules/StepSection";
import {ButtonWrapper} from "../../../components/ButtonWrapper";
import {Button} from "../../../components/Button";
import styles from "./antragsAbschluss.module.scss";
import {Output} from "../../../components/Output";
import {useHistory, useParams} from "react-router-dom";
import axios from "axios";
import {RouteParams} from "../index";

export const AntragsAbschluss: React.FunctionComponent = () => {
    const history = useHistory();
    const params = useParams<RouteParams>();

    const ready = () => {
        axios.post(`${process.env.REACT_APP_API_URL}/turbodarlehen/${params.antragsId!}/ready`).then(() => {
            history.push('/turbodarlehen');
        });
    }

    return (
        <>
            <div>
                <StepSection headline="Vielen Dank für den Abschluss des Prozesses! Dies sind die nächsten Schritte">
                    <div className={styles.wrapper}>
                        <div className={styles.box}>
                            <div className={styles.text} data-number="1">
                                Die Auszahlung des Turbodarlehens wird innerhalb der nächsten 48 Stunden stattfinden.
                            </div>

                            <div className={styles.text} data-number="2">
                                Der Kunde ist dazu verpflichtet einen Nachweis der wohnwirtschaftlichen Verwendung innerhalb
                                der nächsten 4 Wochen zur verfügung zu Stellen.
                            </div>
                        </div>

                        <div className={styles.box}>
                            <div className={styles.linkText}>
                                Link für Nachweis der wohnweirtschaftlichen Verwendung
                            </div>

                            <Output>link.</Output>

                            <button className={styles.link}>Per E-Mail versenden</button>
                        </div>
                    </div>
                </StepSection>
            </div>

            <ButtonWrapper reverse={true}>
                <Button onClick={ready}>Abschließen</Button>
            </ButtonWrapper>
        </>
    );
};
