import React from 'react';
import styles from './error.module.scss';
import {FieldError} from "react-hook-form";

type Props = {
    className?: string,
    error?: FieldError,
}

export const Error: React.FunctionComponent<Props> = (props: Props) => {
    const classNames = [styles.error];

    if (props.className) {
        classNames.push(props.className);
    }

    let message: string | undefined;
    if (Array.isArray(props.error)) {
        props.error.forEach((error: any) => {
            message += error.message;
        })
    } else  {
        message = props.error?.message;
    }

    return (
        <div className={classNames.join(' ')}>
            {message}
        </div>
    );
};
