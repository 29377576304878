import React from 'react';
import {StepSection} from "../../../modules/StepSection";
import {Button} from "../../../components/Button";
import {Link, useParams} from 'react-router-dom';
import {ButtonWrapper} from "../../../components/ButtonWrapper";
import {RouteParams} from "../index";
import styles from "./haushaltsrechnung.module.scss";

export const HaushaltsrechnungAuswaehlen: React.FunctionComponent = () => {
    const params = useParams<RouteParams>();

    return (
        <StepSection headline="Wie möchten Sie die Haushaltsrechnung des Kunden durchführen?">
            <div className={styles.wrapper}>
                <div className={styles.box} >Digitale Haushaltsrechnung</div>
                <Link className={styles.box} to={`/turbodarlehen/antrag/${params.antragsId}/schritt/3`}>Haushaltsrechnung durch Kundennachweis</Link>
            </div>

            <ButtonWrapper reverse={true}>
                <Button to={`/turbodarlehen/antrag/${params.antragsId}/schritt/1`} buttonStyle="secondary">Zurück</Button>
            </ButtonWrapper>
        </StepSection>
    );
};
