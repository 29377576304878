import React from 'react';
import styles from './container.module.scss';

type Props = {
    className?: string,
    children: any,
}

export const Container: React.FunctionComponent<Props> = (props: Props) => {
    const classes = [styles.container];

    if (props.className) {
        classes.push(props.className);
    }

    return (
        <div className={classes.join(' ')}>
            {props.children}
        </div>
    );
};
