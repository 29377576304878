import React from 'react';
import styles from './output.module.scss';

type Props = {
    children: string,
};

export const Output: React.FunctionComponent<Props> = (props: Props) => {
    return (
        <div className={styles.output}>
            {props.children}
        </div>
    );
};
