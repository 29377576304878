import React, {useEffect, useState} from 'react';
import {StepSection} from "../../../modules/StepSection";
import {Input} from "../../../components/Form/Input";
import {Grid} from "../../../components/Form/Gird";
import {Checkbox} from "../../../components/Form/Checkbox";
import {NumberSelect} from "../../../components/Form/NumberSelect";
import {Controller, useForm} from "react-hook-form";
import {Button} from "../../../components/Button";
import {ButtonWrapper} from "../../../components/ButtonWrapper";
import * as yup from "yup";
import {yupResolver} from '@hookform/resolvers/yup';
import {MultiSelect} from "../../../components/Form/MultiSelect";
import {TypeOf} from "yup";
import axios from "axios";
import {useHistory, useParams} from "react-router-dom";
import {RouteParams} from "../index";
import styles from "./finanzierungsvorhaben.module.scss";

const schema = yup.object().shape({
    finanzierungssumme: yup.number().min(0).max(30000).required(),
    modernisierungsart: yup.string().oneOf([
        'dach',
        'heizung',
        'bad-sanitaer',
        'fenster',
        'photovoltaik',
        'fassade',
        'waermedaemmung',
        'bodenbelaege',
    ]).required(),
    immobilie: yup.object().shape({
        personIstEigentuemer: yup.boolean(),
        wohnwirtschaftlicheNutzung: yup.boolean(),
        adresse: yup.object().shape({
            straße: yup.string().required(),
            hausnummer: yup.string().required(),
            postleitzahl: yup.string().length(5).required(),
            ort: yup.string().required(),
        }),
    }),
    personen: yup.array().of(yup.object().shape({
        vorname: yup.string().required(),
        nachname: yup.string().required(),
        geburtsdatum: yup.string().required(),
        email: yup.string().required(),
        familienstand: yup.string().required(),
        iban: yup.string().required(),
        adresse: yup.object().shape({
            straße: yup.string().required(),
            hausnummer: yup.string().required(),
            postleitzahl: yup.string().length(5).required(),
            ort: yup.string().required(),
        }),
    })),
});

interface FormData extends TypeOf<typeof schema> {}

type Props = {
    stepCompleted: (turbodarlehenId: string) => void,
}

export const Finanzierungsvorhaben: React.FunctionComponent<Props> = (props: Props) => {
    const params = useParams<RouteParams>();
    const history = useHistory();

    const [validationError, setValidationError] = useState<string|null>();

    const {register, handleSubmit, errors, clearErrors, control, reset} = useForm<FormData>({
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        if (params.antragsId) {
            axios.get(`${process.env.REACT_APP_API_URL}/turbodarlehen/${params.antragsId}`).then((response) => {
                const data = {
                    finanzierungssumme: response.data.finanzierungssumme,
                    modernisierungsart: response.data.modernisierungsart,
                    immobilie: {
                        personIstEigentuemer: response.data.immobilie.personIstEigentuemer,
                        wohnwirtschaftlicheNutzung: response.data.immobilie.wohnwirtschaftlicheNutzung,
                        adresse: {
                            straße: response.data.immobilie.adresse.straße,
                            hausnummer: response.data.immobilie.adresse.hausnummer,
                            postleitzahl: response.data.immobilie.adresse.postleitzahl,
                            ort: response.data.immobilie.adresse.ort,
                        },
                    },
                    personen: [{
                        vorname: response.data.personen[0].vorname,
                        nachname: response.data.personen[0].nachname,
                        geburtsdatum: new Date(response.data.personen[0].geburtsdatum).toISOString().split('T')[0],
                        email: response.data.personen[0].email,
                        familienstand: response.data.personen[0].familienstand,
                        iban: response.data.personen[0].iban,
                        adresse: {
                            straße: response.data.immobilie.adresse.straße,
                            hausnummer: response.data.immobilie.adresse.hausnummer,
                            postleitzahl: response.data.immobilie.adresse.postleitzahl,
                            ort: response.data.immobilie.adresse.ort,
                        },
                    }],
                };

                // @ts-ignore
                reset(data);
            }).catch(() => {
                history.push('/turbodarlehen/neu');
            });
        }
    }, [reset, params, history]);

    const onSubmit = (data: FormData) => {
        const person = data.personen![0];

        // validierung gegen Validierungsservice
        axios.post('https://personaldata-calculation-v1-5a7pijy65q-ew.a.run.app', {
                "end2endId": "lc-1612123184",
                "clientId": "bsw",
                "calculations": [
                    {
                        "productId": "turbo",
                        "postalCode": person.adresse.postleitzahl,
                        "emailAdress": person.email,
                        "birthday": new Date(person.geburtsdatum ?? '').toLocaleDateString('de', {day: '2-digit', month: '2-digit', year: 'numeric'}),
                        "maritalStatus": person.familienstand,
                        "firstName": person.vorname,
                        "surName": person.nachname,
                        "city": person.adresse.ort,
                        "iban": "DE75512108001245126199",
                    }
                ]
        })
        .then((response) => {
            const validationData = response.data.calculations;

            if (validationData[0].errorcode === undefined) {
                axios.post('https://fraudprevention-v1-faucy7y7oq-ew.a.run.app', {
                    "clientId": "bsw",
                    "calculations": [
                        {
                            "iban": person.iban,
                            "productId": "credit"
                        }
                    ]
                })
                .then((response) => {
                    setValidationError(undefined);
                    if (params.antragsId) {
                        axios.post(process.env.REACT_APP_API_URL + '/turbodarlehen/' + params.antragsId, data).then((response) => {
                            props.stepCompleted(params.antragsId!);
                        });
                    } else {
                        axios.post(process.env.REACT_APP_API_URL + '/turbodarlehen', data).then((response) => {
                            props.stepCompleted(response.data.id);
                        });
                    }
                })
                .catch((error) => {
                    const noIban = error.response.data.calculations ?? false;
                    const noIbanError = noIban[0] ?? false;
                    if (noIbanError.frontend_message ?? false) {
                        setValidationError(error.response.data.calculations[0].frontend_message);
                    } else {
                        setValidationError(`
                            BIC: ${error.response.data.bic} <br>
                            source: ${error.response.data.source} <br>
                            date: ${error.response.data.date} <br>
                            reason: ${error.response.data.reason} <br>
                            state: ${error.response.data.state}
                        `);
                    }
                });
            } else {
                setValidationError(validationData[0].frontend_message);
            }
        });
    };

    const onChange = (name: string) => {
        clearErrors(name);
    }

    let personenError;
    let adressenError;
    if (errors.personen) {
        personenError = errors.personen[0] ?? undefined;

        if (personenError && personenError.adresse) {
            adressenError = personenError.adresse ?? undefined;
        }
    }


    let buttonLabel = "Erstellen";
    if (params.antragsId) {
        buttonLabel = "Speichern";
    }

    const errorElement = validationError ? (
        <div className={styles.error} dangerouslySetInnerHTML={{__html: validationError}} />
    ) : '';

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div>
                <StepSection headline="Wie hoch ist der Finanzierungswunsch ihres Kunden?">
                    <Controller
                        name="finanzierungssumme"
                        control={control}
                        defaultValue={30000}
                        render={({ onChange, value }) => <NumberSelect error={errors.finanzierungssumme} onChange={(value: number) => onChange(value)} suffix="€" step={1000} value={value}/>}
                    />
                </StepSection>

                <StepSection headline="Was für eine Modernisierung plant der Kunde?">
                    <Controller
                        name="modernisierungsart"
                        control={control}
                        render={({ onChange, value }) => (
                            <MultiSelect error={errors.modernisierungsart} value={value} onChange={onChange} options={[
                                {
                                    title: "Dach",
                                    name: "dach",
                                },
                                {
                                    title: "Heizung",
                                    name: "heizung",
                                },
                                {
                                    title: "Bad/ Sanitär",
                                    name: "bad-sanitaer",
                                },
                                {
                                    title: "Fenster",
                                    name: "fenster",
                                },
                                {
                                    title: "Photovoltaik/ Solar",
                                    name: "photovoltaik",
                                },
                                {
                                    title: "Fassade",
                                    name: "fassade",
                                },
                                {
                                    title: "Wärmedämmung",
                                    name: "waermedaemmung",
                                },
                                {
                                    title: "Bodenbeläge",
                                    name: "bodenbelaege",
                                }
                            ]} />
                        )}
                    />
                </StepSection>

                <StepSection headline="Wie lautet die Anschrift der Immobilie?">
                    <Grid>
                        <Input onChange={onChange} error={errors.immobilie?.adresse?.straße} name="immobilie.adresse.straße" register={register} placeholder="Straße" />
                        <Input onChange={onChange} error={errors.immobilie?.adresse?.hausnummer} name="immobilie.adresse.hausnummer" register={register} placeholder="Hausnummer" />
                        <Input onChange={onChange} error={errors.immobilie?.adresse?.postleitzahl} name="immobilie.adresse.postleitzahl" register={register} placeholder="PLZ" />
                        <Input onChange={onChange} error={errors.immobilie?.adresse?.ort} name="immobilie.adresse.ort" register={register} placeholder="Ort" />
                    </Grid>

                    <Checkbox onChange={(name) => onChange(name)} error={errors.personIstEigentuemer} register={register} name="immobilie.personIstEigentuemer" label="Kunde ist Eigentümer der Immobilie" />
                    <Checkbox onChange={(name) => onChange(name)} error={errors.wohnwirtschaftlicheNutzung} register={register} name="immobilie.wohnwirtschaftlicheNutzung" label="Bestätigung der wohnwirtschaftlichen Nutzung" />
                </StepSection>

                <StepSection headline="Wir benötigen einige persönliche Daten Ihres Kundens">
                    <Grid>
                        <Input onChange={onChange} error={personenError?.vorname} name="personen[0].vorname" register={register} placeholder="Vorname" />
                        <Input onChange={onChange} error={personenError?.nachname} name="personen[0].nachname" register={register} placeholder="Nachname" />
                        <Input onChange={onChange} type="date" error={personenError?.geburtsdatum} name="personen[0].geburtsdatum" register={register} placeholder="Geburtsdatum" />
                        {/*@ts-ignore*/}
                        <Input onChange={onChange} error={adressenError?.straße} name="personen[0].adresse.straße" register={register} placeholder="Straße" />
                        <Input onChange={onChange} error={adressenError?.hausnummer} name="personen[0].adresse.hausnummer" register={register} placeholder="Hausnummer" />
                        {/*@ts-ignore*/}
                        <Input onChange={onChange} error={adressenError?.postleitzahl} name="personen[0].adresse.postleitzahl" register={register} placeholder="PLZ" />
                        {/*@ts-ignore*/}
                        <Input onChange={onChange} error={adressenError?.ort} name="personen[0].adresse.ort" register={register} placeholder="Ort" />
                        {/*@ts-ignore*/}
                        <Input onChange={onChange} error={personenError?.email} name="personen[0].email" register={register} placeholder="E-Mail" />
                        <Input onChange={onChange} error={personenError?.familienstand} name="personen[0].familienstand" register={register} placeholder="Familienstand" />

                        <Input onChange={onChange} error={personenError?.iban} name="personen[0].iban" register={register} placeholder="Iban" />
                    </Grid>
                </StepSection>

                {errorElement}
            </div>

            <ButtonWrapper>
                <Button to="/turbodarlehen/uebersicht">Zurück</Button>
                <Button type="submit">{buttonLabel}</Button>
            </ButtonWrapper>
        </form>
    );
};
