import React from 'react';
import styles from './step-section.module.scss';
import {Headline} from "../../components/Headline";

type Props = {
    headline: string,
    children: any,
};

export const StepSection: React.FunctionComponent<Props> = (props: Props) => {
    return (
        <div className={styles.wrapper}>
            <Headline type="h2" className={styles.headline}>{props.headline}</Headline>

            {props.children}
        </div>
    );
};
