import React from 'react';
import styles from './button-wrapper.module.scss';

type Props = {
    children: any,
    reverse?: boolean,
}

export const ButtonWrapper: React.FunctionComponent<Props> = (props: Props) => {
    const reverse = props.reverse ?? false;

    return (
        <div className={reverse ? [styles.wrapper, styles.reverse].join(' ') : styles.wrapper}>
            {props.children}
        </div>
    );
};
