import React from 'react';
import styles from './results.module.scss';

interface Props {
    columns: Column[],
}

type Column = {
    results: Result[],
}

type Result = {
    label: string,
    value: string,
}

export const Results: React.FunctionComponent<Props> = (props: Props) => {
    const columns = props.columns.map((column: Column, columnIndex: number) => {
        const results = column.results.map((result: Result, index: number) => {
            return (
                <div key={index} className={styles.element}>
                    <div className={styles.label}>{result.label}</div>
                    <div className={styles.value}>{result.value}</div>
                </div>
            );
        });

        return (
            <div key={columnIndex} className={styles.column}>
                {results}
            </div>
        );
    })

    return (
        <div className={styles.wrapper}>
            {columns}
        </div>
    );
};
